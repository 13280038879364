<template>
  <v-container>
    <div class="d-block d-md-flex">
      <AppAside></AppAside>
      <div class="ps-5 page_content grow">
        <v-form ref="form">
          <v-card>
            <v-card-title> התראות </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-4">
              <v-text-field
                :rules="[(v) => !!v || 'required']"
                outlined
                v-model="notifications.title"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  כותרת
                </template>
              </v-text-field>
              <v-text-field
                label="פרטי התראה"
                outlined
                v-model="notifications.body"
              ></v-text-field>
              <v-file-input
                v-if="$store.getters.permission('notify_topic_img')"
                label="תמונה"
                outlined
                accept="image/*"
                prepend-icon=""
                prepend-inner-icon="image"
                v-model="notifications.image"
              ></v-file-input>
              <!-- <v-select
                :items="notifications_types"
                item-text="text"
                item-value="type"
                outlined
                v-model="notifications.type"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  {{ trans("messages", "messages_topic_label") }}
                </template>
              </v-select> -->
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                :loading="save_loader"
                :disabled="save_loader"
                @click="send()"
                color="primary"
              >
                שלח התראה
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
        <v-snackbar left v-model="snackbar">
          <p class="white--text">נשלחה בהצלחה</p>
          <template v-slot:action>
            <v-icon color="green" class="ms-3">check_circle</v-icon>
          </template>
        </v-snackbar>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  props: ["notification"],
  name: "messages",
  data() {
    return {
      save_loader: false,
      snackbar: false,
      notifications: {
        title: this.$route.query.msg || "",
        body: this.$route.query.msg || "",
        image: null,
        topic: "testing",
      },
    };
  },
  computed: {
    // notifications_types() {
    //   return [
    //     {
    //       type: "customers",
    //       text:  this.trans("messages", "messages_topic_customers"),
    //     },
    //     {
    //       type: "branches",
    //       text:  this.trans("messages", "messages_topic_branches"),
    //     },
    //     {
    //       type: "deliveries",
    //       text:  this.trans("messages", "messages_topic_delivery"),
    //     },
    //   ];
    // },
  },
  methods: {
    close_notifications() {
      this.notifications = {
        title: "",
        body: "",
        image: null,
        type: "testing",
      };
      this.$emit("close_notification");
      this.$refs.form.resetValidation();
    },
    send() {
      if (!this.save_loader && this.$refs.form.validate()) {
        this.save_loader = true;
        const formData = new FormData();
        formData.append("title", this.notifications.title);
        formData.append("body", this.notifications.body);
        formData.append("image", this.notifications.image || "");
        formData.append("topic", this.notifications.topic);
        this.$store
          .dispatch("public__request", {
            config: {
              url: "send_messages",
              method: "post",
            },
            data: formData,
          })
          .then((res) => {
            this.snackbar = true;
            this.save_loader = false;
            this.close_notifications();
          });
      }
    },
  },
};
</script>
